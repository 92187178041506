import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './User/userPage.css'
import { useAddedSongs } from "../components/AddedSongsContext";
import { useNavigate } from "react-router-dom";


function SongDetail() {
  const { songCode } = useParams();
  const [songWork, setsongWork] = useState(null);
  const {  addedSongs, addSong } = useAddedSongs();
  const navigate = useNavigate();

  useEffect(() => {
    // Gửi yêu cầu lên server để lấy thông tin chi tiết của tác giả dựa trên mã tác giả
    const fetchSongDetail = async () => {
      try {
        // const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/api/song/${songCode}`);
        const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/user/api/song/${songCode}`);

        if (response.ok) {
          const data = await response.json();
          setsongWork([data]);
        } else {
          console.error("Lỗi khi lấy thông tin chi tiết bai hat");
        }
      } catch (err) {
        console.error("Có lỗi xảy ra:", err);
      }
    };

    fetchSongDetail();
  }, [songCode]);

  const isSongAdded = song => {
    return addedSongs.some(addedSong => addedSong.ma_code_bai_hat === song.ma_code_bai_hat);
  };

  const handleAddClick = (song) => {
    addSong(song);
}

const handleGoBack = () => {
  navigate(-1); 
}
 

  return (
    <div>
<div>
        <button className="go-back-button" onClick={handleGoBack}> &laquo; Quay lại</button>
      </div>
      {songWork && (
        <table>
          <thead>
            <tr>
              <th className="column-stt">STT</th>
              <th className="column-ma">Code</th>
              <th className="column-ten">Tên Bài Hát</th>
              <th>Nhạc</th>
              <th>Lời</th>
              <th>Nhà Xuất Bản</th>
              <th>Trạng Thái</th>
              <th>Note</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {songWork.map((song, index) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td className="ma-code">{song.ma_code_bai_hat}</td>
                <td>{Array.isArray(song.ten_bai_hat) ? song.ten_bai_hat.join(', ') : song.ten_bai_hat}</td>
                <td>{song.nhac}</td>
                <td>{song.loi}</td>
                <td>{song.publisher}</td>
                <td>{song.status}</td>
                <td>{song.note}</td>
                <td>
                                {isSongAdded(song) ? 
                                    <button disabled className="btn-added">Đã Thêm</button> : 
                                    <button onClick={() => handleAddClick(song)} className="btn-add">Thêm</button>
                                }
                            </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
  

    </div>
  )
}

export default SongDetail
