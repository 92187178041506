import React, { useState, useEffect } from 'react';
import { useAddedSongs } from '../../components/AddedSongsContext';
import './addedSongsPage.css'
import axios from 'axios';
import { useUser } from '../../components/UserContext';
import jwtDecode from 'jwt-decode';
import {  useNavigate } from "react-router-dom";
import moment from 'moment';

function AddedSongsPage() {
  const { addedSongs, removeSong, resetSongs } = useAddedSongs();
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
  const [programName, setProgramName] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();


  const handleRemoveSong = (songIndex) => {
    removeSong(songIndex);
  };
 
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedData = jwtDecode(token);
        if (decodedData && decodedData.email) {
          setEmail(decodedData.email);  // Thiết lập giá trị mặc định cho trường email từ thông tin người dùng
        }
      } catch (e) {
        console.error("Failed to decode token:", e);
      }
    }
  }, []);
  



  const handleSubmit = (event) => {
    event.preventDefault();
    setShowConfirmModal(true);
  };

  const finalSubmit = async () => {
    // Giải mã token
    const token = localStorage.getItem("token");
    let decodedData = {};
    if (token) {
      try {
        decodedData = jwtDecode(token);
      } catch (e) {
        console.error("Failed to decode token:", e);
        // Hiển thị thông báo lỗi hoặc xử lý lỗi ở đây
        return;  // Nếu có lỗi xảy ra khi giải mã, dừng hàm
      }
    }
  
    // Cập nhật payload với thông tin từ token
    const payload = { 
      addedSongs, 
      email: decodedData.email || email,  // Sử dụng email từ token hoặc từ state nếu không có
      note, 
      programName,
      username: decodedData.username || user?.username  // Sử dụng username từ token hoặc từ state nếu không có
    };
  
    try {
      const response = await axios.post('https://artfinder-backend-git-main-nvtrung30796.vercel.app/user/api/send-email', payload);
      if (response.status === 200) {
        console.log('Email sent successfully.');
        
        /////////////////////////////////////////////////
        // Gọi API để lưu thông tin vào cơ sở dữ liệu
        const historyData = {
          addedSongs,
          email: decodedData.email || email,
          note,
          programName,
          username: decodedData.username || user?.username,
          timestamp: moment().format('DD-MM-YYYY HH:mm:ss')
        };
        
        const saveToHistoryResponse = await axios.post('https://artfinder-backend-git-main-nvtrung30796.vercel.app/user/save-to-history', historyData);

        if (saveToHistoryResponse.status === 200) {
          console.log('Data saved to history successfully.');
        } else {
          console.error('Failed to save data to history:', saveToHistoryResponse.data.error);
        }



        /////////////////////////////////////////////////

        setShowSuccessModal(true);
        setShowConfirmModal(false);
        setNote('');  // reset note field
        resetSongs(); // reset the added songs list
      } else {
        // Xử lý các trường hợp khác
        setShowErrorModal(true); // Hiển thị modal thông báo lỗi
        setShowConfirmModal(false);
      }
    } catch (error) {
      console.error('Failed to send email:', error);
      setShowErrorModal(true);  // Hiển thị modal thông báo lỗi
    }
  };

  const handleCloseModals = () => {
    setShowConfirmModal(false);
    setShowErrorModal(false);
    setShowSuccessModal(false)
  };

  
    const handleGoBack = () => {
      navigate(-1); 
    }
  

  return (
    <div>
      <div>
        <button className="go-back-button m-2"  onClick={handleGoBack}>Quay lại</button>
      </div>
      <h1 className='text-center p-2'>Danh Sách Bài Hát</h1>
      
      {addedSongs && addedSongs.length > 0 ? (
        <table className='table-addedsongs'>
          <thead>
            <tr>
              <th className="column-stt">STT</th>
              <th className="column-ma">Code</th>
              <th className="column-ten-addedsongs">Tên Bài Hát</th>
              <th>Nhạc</th>
              <th>Lời</th>
              <th>Nhà Xuất Bản</th>
              <th>Trạng Thái</th>
              <th>Note</th>
              <th className='action-remove'></th>
            </tr>
          </thead>
          <tbody>
            {addedSongs.map((song, index) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td className="ma-code">{song.ma_code_bai_hat}</td>
                <td>{Array.isArray(song.ten_bai_hat) ? song.ten_bai_hat.join(', ') : song.ten_bai_hat}</td>
                <td>{song.nhac}</td>
                <td>{song.loi}</td>
                <td>{song.publisher}</td>
                <td>{song.status}</td>
                <td>{song.note}</td>
                <td>
                  <button className='btn-remove' onClick={() => handleRemoveSong(index)}>Xóa</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className='m-2'>Không có bài hát nào được thêm.</p>
      )}
        <div className="form-container-addedsongspage">
            <h3 className='mb-3'>Vui lòng điền đầy đủ thông tin!</h3>
            <form onSubmit={handleSubmit} className="custom-form">
                <input
                    type="email"
                    placeholder="Nhập địa chỉ email của bạn"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="custom-form-input"
                />
                <input 
                    type="text" 
                    placeholder="Nhập tên chương trình" 
                    value={programName} 
                    onChange={(e) => setProgramName(e.target.value.toUpperCase())} 
                    className="custom-form-input"
                    required
                />
                <textarea
                    placeholder="Nhập ghi chú..."
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="custom-form-textarea"
                    required
                />
                <button type="submit" className="custom-form-button">Gửi thông tin</button>
            </form>
        </div>

      {showConfirmModal && (
        <div className="confirm-modal modal-background">
          <div className="modal-content confirm-modal-content">
            <h2 className="confirm-modal-title">Kiểm Tra Danh Sách Trước Khi Gửi</h2>
            <div className="song-list confirm-modal-song-list">
              {addedSongs.map((song, index) => (
                <p key={index} className="confirm-modal-song-item">
                  {index + 1}. Code: {song.ma_code_bai_hat} - Tên Bài Hát: {song.ten_bai_hat}
                </p>
              ))}
            </div>
            <button onClick={finalSubmit} className="confirm-modal-submit-btn">Xác Nhận Gửi</button>
            <button onClick={() => setShowConfirmModal(false)} className="confirm-modal-cancel-btn">Hủy</button>
          </div>
        </div>

      )}


      {showSuccessModal && (
        <div className="success-modal">
          <div className="modal-content">
            <h2>Thành công!</h2>
            <p>Danh sách bài hát của bạn đã được gửi. Chúng tôi sẽ kiểm tra và phản hồi sớm!</p>
            <button className='success-modal-btn' onClick={handleCloseModals}>Đóng</button>
          </div>
        </div>
      )}

      {showErrorModal && (
        <div className="error-modal">
          <div className="modal-content">
            <h2>Lỗi!</h2>
            <p>Đã xảy ra sự cố khi gửi email. Vui lòng thử lại sau.</p>
            <button className='error-modal-btn' onClick={handleCloseModals}>Đóng</button>
          </div>
        </div>
      )}


    </div>
  );
}

export default AddedSongsPage;
