import React, { useState, useEffect } from 'react';
import './manageUsers.css';
import { addDays } from 'date-fns';

function ManageUsers() {
    const [infoUsers, setInfoUsers] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [newUserData, setNewUserData] = useState({
        username: '',
        password: '',
        email: '',
        expirationDays: ''
    });
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const promptDeleteUser = (userId) => {
        setUserIdToDelete(userId);
        // console.log("get-ID", userId)
        setShowConfirmDelete(true);
    };

    const handleAddUser = () => {
        setShowAddUserModal(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUserData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmitUser = async () => {
        if (!newUserData.username || !newUserData.password || !newUserData.email || !newUserData.expirationDays) {
            return;
        }

        try {
            // Gửi dữ liệu người dùng lên server
            const response = await fetch('https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/admin-add-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newUserData)
            });

            const data = await response.json();

            if (response.status === 201) {
                // Tính toán expirationDate dựa trên expirationDays
                const expirationDays = newUserData.expirationDays;
                const currentDate = new Date();
                const expirationDate = addDays(currentDate, expirationDays);

                // Tạo người dùng mới với expirationDate và cập nhật state infoUsers
                const userWithExpirationDate = { ...newUserData, expirationDate: expirationDate, isActive: true };
                setInfoUsers([...infoUsers, userWithExpirationDate]);

                // Đặt các giá trị mới cho newUserData và hiển thị modal
                setNewUserData({
                    username: '',
                    password: '',
                    email: '',
                    expirationDays: ''
                });
                setShowAddUserModal(false);
            } else {
                alert(data.error);
            }
        } catch (error) {
            console.error("Lỗi khi thêm người dùng:", error);
        }
    };

    useEffect(() => {
        fetch('https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/admin-get-accounts')
            .then(response => response.json())
            .then(data => {
                setInfoUsers(data);
            })
            .catch(error => {
                console.error("There was an error fetching users:", error);
            });
    }, []);


    const confirmDeleteUser = async () => {
        try {
            const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/admin-delete-user/${userIdToDelete}`, {
                method: 'DELETE'
            });

            const data = await response.json();

            if (response.status === 200) {
                // Cập nhật lại danh sách người dùng sau khi xóa
                const updatedUsers = infoUsers.filter(user => user._id !== userIdToDelete);
                setInfoUsers(updatedUsers);
            } else {
                alert(data.message || 'Lỗi khi xóa người dùng.');
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            alert('Lỗi khi xóa người dùng.');
        }
        setShowConfirmDelete(false);
        setUserIdToDelete(null);
    };

    const handleToggleUserStatus = async (userId, isActive) => {
        try {
          const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/admin-toggle-user-status/${userId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ action: isActive ? 'block' : 'unblock' }),
          });
    
          if (response.ok) {
            // Nếu API trả về thành công (status 200), cập nhật trạng thái trong giao diện
            const updatedUsers = infoUsers.map((user) => {
              if (user._id === userId) {
                return { ...user, isActive: !isActive };
              }
              return user;
            });
            setInfoUsers(updatedUsers);
          } else {
            // Xử lý lỗi nếu API trả về lỗi
            const errorData = await response.json();
            console.error('Lỗi khi thay đổi trạng thái người dùng:', errorData);
          }
        } catch (error) {
          console.error('Lỗi kết nối:', error);
        }
      };

    return (
        <div className="manage-users-container">
            <h2>Quản lý người dùng</h2>
            <button className="add-user-button" onClick={handleAddUser}>Thêm người dùng mới</button>

            {/* Modal để thêm người dùng */}
            {showAddUserModal && (
                <div className="manageusers-addnewuser-modal">
                    <div className="manageusers-addnewuser-modal-content">
                        <h2 className='manageusers-addnewuser-modal-title'>Thêm người dùng mới</h2>
                        <input
                            type="text"
                            name="username"
                            placeholder="Tên người dùng"
                            value={newUserData.username}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Mật khẩu"
                            value={newUserData.password}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={newUserData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="number"
                            name="expirationDays"
                            placeholder="Số ngày sử dụng"
                            value={newUserData.expirationDays}
                            onChange={handleInputChange}
                            required
                        />
                        <button className='addnewuser-modal-save-btn' onClick={handleSubmitUser}>Lưu</button>
                        <button className='addnewuser-modal-remove-btn' onClick={() => setShowAddUserModal(false)}>Hủy</button>
                    </div>
                </div>
            )}

            {isProcessing && (
                <div className="processing-overlay">
                    <div className="processing-spinner"></div>
                    <div>Đang xử lý yêu cầu...</div>
                </div>
            )}
            <table className="manage-users-table">
                <thead>
                    <tr>
                        <th>Tên người dùng</th>
                        <th>Mật khẩu</th>
                        <th>Email</th>
                        <th>Ngày hết hạn</th>
                        <th>Trạng thái</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {infoUsers.map((user, index) => (
                        <tr key={index}>
                            <td>{user && user.username}</td>
                            <td>{user && user.password}</td>
                            <td>{user && user.email}</td>
                            <td>{user && user.expirationDate ? user.expirationDate.toLocaleString() : 'N/A'}</td>
                            <td>
                                {user && user.isActive ? (
                                    <span className="status-green-dot"></span>
                                ) : (
                                    <span className="status-red-dot"></span>
                                )}
                            </td>
                            <td>
                                <button onClick={() => promptDeleteUser(user._id)} className="delete-button">Xóa</button>
                                <button onClick={() => handleToggleUserStatus(user._id, user.isActive)} className="toggle-status-button">
                                    {user.isActive ? "Khóa" : "Mở khóa"}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showConfirmDelete && (
                <div className="confirm-delete-modal">
                    <div className="confirm-delete-modal-content">
                        <h2 className='confirm-delete-modal-title'>Bạn có chắc muốn xóa tài khoản này?</h2>
                        <button onClick={confirmDeleteUser} className="confirm-delete-modal-confirm-btn">Xác nhận</button>
                        <button onClick={() => setShowConfirmDelete(false)} className="confirm-delete-modal-cancel-button">Hủy</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ManageUsers;
