import React, { useState } from 'react';
import './manageSongs.css';

function ManageSongs() {
    const [songs, setSongs] = useState([]);
    const [searchByName, setSearchByName] = useState('');
    const [searchByCode, setSearchByCode] = useState('');
    const [error, setError] = useState(null);
    const [addNewSongPopup, setAddNewSongPopup] = useState(false);
    const [newSongData, setNewSongData] = useState({
        ma_code_bai_hat: '',
        ten_bai_hat: '',
        nhac: '',
        loi: '',
        publisher: '',
        status: '',
        note: '',
        ma_tac_gia: ''
    });
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [songIdToDelete, setSongIdToDelete] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSongData(prevData => ({ ...prevData, [name]: value }));
        // console.log('data', newSongData)
    };

    const handleSearchByName = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch("https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/searchSongByName", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ten_bai_hat: searchByName }),
            });

            if (response.ok) {
                const data = await response.json();
                setError(null);

                if (data.length === 0) {
                    // Không có bài hát nào được tìm thấy
                    setSongs([]);
                    setError("Không tìm thấy bài hát");

                } else {
                    // Có bài hát được tìm thấy
                    setSongs(data);
                    setError(null); // Đặt lại lỗi thành null khi có dữ liệu hợp lệ
                }
            } else if (response.status === 404) {
                // Không tìm thấy bài hát
                setSongs([]);
                setError("Không tìm thấy bài hát");
            } else {
                // Lỗi không xác định
                setError("Lỗi không xác định");
            }
        } catch (error) {
            // Lỗi kết nối đến server
            console.error("Có lỗi xảy ra khi gửi yêu cầu đến server:", error);
            setError("Có lỗi xảy ra khi kết nối đến server.");
        }
    };



    const handleSearchByCode = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch("https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/searchSongByCode", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ma_code_bai_hat: searchByCode }),
            });

            if (response.ok) {
                const data = await response.json();
                setError(null);

                if (data.length === 0) {
                    // Không có bài hát nào được tìm thấy
                    setSongs([]);
                    setError("Không tìm thấy bài hát");
                } else {
                    // Có bài hát được tìm thấy
                    setSongs(data);
                    setError(null); // Đặt lại lỗi thành null khi có dữ liệu hợp lệ
                }
            } else if (response.status === 404) {
                // Không tìm thấy bài hát
                setSongs([]);
                setError("Không tìm thấy bài hát");
            } else {
                // Lỗi không xác định
                setError("Lỗi không xác định");
            }
        } catch (error) {
            // Lỗi kết nối đến server
            console.error("Có lỗi xảy ra khi gửi yêu cầu đến server:", error);
            setError("Có lỗi xảy ra khi kết nối đến server.");
        }
    };




    const handleAddSongClick = () => {
        // Khi người dùng nhấn vào nút "Thêm tác phẩm mới"
        setAddNewSongPopup(true); // Hiện popup
    };
    const handleAddSong = async () => {
        // Kiểm tra các trường bắt buộc trước khi gửi yêu cầu
        if (
            !newSongData.ma_code_bai_hat ||
            !newSongData.ten_bai_hat ||
            !newSongData.nhac ||
            !newSongData.loi ||
            !newSongData.status ||
            !newSongData.ma_tac_gia
        ) {
            alert('Vui lòng điền đầy đủ thông tin cho các trường.');
            return;
        }

        // Lấy dữ liệu từ trường nhập trong giao diện người dùng
        const requestData = {
            ma_code_bai_hat: newSongData.ma_code_bai_hat,
            ten_bai_hat: newSongData.ten_bai_hat,
            nhac: newSongData.nhac,
            loi: newSongData.loi,
            publisher: newSongData.publisher,
            status: newSongData.status,
            note: newSongData.note,
            ma_tac_gia: newSongData.ma_tac_gia
        };

        try {
            // Gửi dữ liệu bài hát lên server
            const response = await fetch('https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/admin-add-song', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData), // Sử dụng requestData thay vì newSongData
            });

            const data = await response.json();

            if (response.status === 201) {
                console.log('Bài hát đã được thêm mới thành công.');
                // Sau khi thêm thành công, bạn có thể thực hiện các thao tác khác ở đây

                // Đóng popup
                setAddNewSongPopup(false);
            } else {
                alert(data.error);
            }
        } catch (error) {
            console.error('Lỗi khi thêm bài hát:', error);
            // Xử lý lỗi (hiển thị thông báo lỗi hoặc thực hiện các thao tác khác nếu cần)
        }
    };

    const promptDeleteSong = (songId) => {
        setSongIdToDelete(songId);
        console.log("testSongID", songId)

        setShowConfirmDelete(true);
    };
    const confirmDeleteSong = async () => {
        try {
            const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/admin-delete-song/${songIdToDelete}`, {
                method: 'DELETE'
            });

            const data = await response.json();

            if (response.status === 200) {
                const updatedSongs = songs.filter(song => song._id !== songIdToDelete);
                setSongs(updatedSongs);

                // Cập nhật lại danh sách người dùng sau khi xóa

            } else {
                alert(data.message || 'Lỗi khi xóa người dùng.');
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            alert('Lỗi khi xóa người dùng.');
        }
        setShowConfirmDelete(false);
        setSongIdToDelete(null);
    };

    return (
        <div className="manage-songs-container">
            <h2 className="manage-songs-title">Quản lý tác phẩm</h2>
            <button className="manage-songs-add-song-button" onClick={handleAddSongClick} >Thêm tác phẩm mới</button>


            {addNewSongPopup && (
                <div className="managesongs-addnewsong-modal">
                    <div className="manageusers-addnewuser-modal-content">
                        <h2 className='manageusers-addnewuser-modal-title'>Thêm tác phẩm mới</h2>
                        <input
                            type="text"
                            name="ma_code_bai_hat"
                            placeholder="Mã code bài hát"
                            value={newSongData.ma_code_bai_hat}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="ten_bai_hat"
                            placeholder="Tên bài hát"
                            value={newSongData.ten_bai_hat}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="nhac"
                            value={newSongData.nhac}
                            placeholder="Nhạc"
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="loi"
                            value={newSongData.loi}
                            placeholder="Lời"
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="publisher"
                            value={newSongData.publisher}
                            placeholder="Publisher"
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            name="status"
                            value={newSongData.status}
                            placeholder="Status"
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="note"
                            value={newSongData.note}
                            placeholder="Note"
                            onChange={handleInputChange}

                        />
                        <input
                            type="text"
                            name="ma_tac_gia"
                            value={newSongData.ma_tac_gia}
                            placeholder="Mã tác giả"
                            onChange={handleInputChange}
                            required
                        />

                        <button className='addnewsong-modal-save-btn' onClick={handleAddSong}>Lưu</button>
                        <button className='addnewuser-modal-remove-btn' onClick={() => setAddNewSongPopup(false)}>Hủy</button>
                    </div>
                </div>
            )}

            <form onSubmit={handleSearchByName} className="manage-songs-search-form">
                <div className="manage-songs-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo tên bài hát"
                        value={searchByName}
                        onChange={(e) => setSearchByName(e.target.value)}
                    />
                </div>
                <button className="manage-songs-search-button" type="submit">
                    Tìm kiếm
                </button>
            </form>

            <form onSubmit={handleSearchByCode} className="manage-songs-search-form">
                <div className="manage-songs-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo mã code bài hát"
                        value={searchByCode}
                        onChange={(e) => setSearchByCode(e.target.value)}
                    />
                </div>
                <button className="manage-songs-search-button" type="submit">
                    Tìm kiếm
                </button>
            </form>

            {error && <p className="error">{error}</p>}

            {songs && (
                <table className="manage-songs-table">
                    <thead>
                        <tr>
                            <th className="column-stt">STT</th>
                            <th className="column-ma">Code</th>
                            <th className="column-ten">Tên Bài Hát</th>
                            <th>Nhạc</th>
                            <th>Lời</th>
                            <th>Nhà Xuất Bản</th>
                            <th>Trạng Thái</th>
                            <th>Note</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {songs.map((song, index) => (
                            <tr key={index}>
                                <td>{index + 1}.</td>
                                <td className="ma-code">{song.ma_code_bai_hat}</td>
                                <td>{Array.isArray(song.ten_bai_hat) ? song.ten_bai_hat.join(', ') : song.ten_bai_hat}</td>
                                <td>{song.nhac}</td>
                                <td>{song.loi}</td>
                                <td>{song.publisher}</td>
                                <td>{song.status}</td>
                                <td>{song.note}</td>
                                <td>
                                    <button className='btn-remove' onClick={() => promptDeleteSong(song._id)}>Xóa</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {showConfirmDelete && (
                <div className="confirm-delete-modal">
                    <div className="confirm-delete-modal-content">
                        <h2 className='confirm-delete-modal-title'>Bạn có chắc muốn xóa tác phẩm này?</h2>
                        <button onClick={confirmDeleteSong} className="confirm-delete-modal-confirm-btn">Xác nhận</button>
                        <button onClick={() => setShowConfirmDelete(false)} className="confirm-delete-modal-cancel-button">Hủy</button>
                    </div>
                </div>
            )}

        </div>
    );
}

export default ManageSongs;

