import React, { useState, useEffect } from "react";
import "./userPage.css";
import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import SongsContext from "../../components/SongsContext";
import { useUser } from "../../components/UserContext";
import { useAddedSongs } from "../../components/AddedSongsContext";
import jwtDecode from 'jwt-decode';


function UserPage() {
  const [songName, setSongName] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [songs, setSongs] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [error, setError] = useState(null);
  const [displayType, setDisplayType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authorWork, setAuthorWork] = useState(null);
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const { resetSongs } = useAddedSongs();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [shouldResetSongName, setShouldResetSongName] = useState(false);
  const [savedSongName, setSavedSongName] = useState("");
  const token = localStorage.getItem('token');

  // Giải mã token và lấy thông tin người dùng (ví dụ username)
  let username = null;
  let isActiveUser = null;
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      username = decodedToken.username; // giả sử payload của bạn có trường 'username'
    } catch (error) {
      console.error('Error decoding the token', error);
    }
  }

  const contextValue = {
    songs: songs,
    authors: authors,
    error: error
  };

  const confirmLogout = () => {
    // 1. Xóa token khỏi localStorage
    localStorage.removeItem('token');

    // 2. Xóa songs khỏi localStorage
    localStorage.removeItem('songs');

    // 3. Xóa songName khỏi localStorage
    localStorage.removeItem('songName');

    // 4. Đặt trạng thái người dùng về null
    setUser(null);

    // 5. Chuyển hướng người dùng về trang chính
    navigate("/");

    // 6. Đặt lại danh sách các bài hát đã thêm
    resetSongs();

    // 7. Đóng modal đăng xuất
    setShowLogoutModal(false);
  }

  const handleLogout = () => {
    setShowLogoutModal(true);
  };


  const handleSongNameSubmit = async (event) => {
    event.preventDefault();
    if (!songName.trim()) {
      setError("Vui lòng nhập tên bài hát");
      setSongs([]);
      setAuthors([]);
      setAuthorWork([]);
      return;
    }
  
    setLoading(true);
  
    let requestData = {
      ten_bai_hat: songName,
      username: username,
    };
    
    try {
      const response = await fetch("https://artfinder-backend-git-main-nvtrung30796.vercel.app/user/searchSongByName", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        const dataSong = await response.json();
        setSongs(dataSong);
        setError(null);
        setDisplayType("songs");
      } else {
        if (response.status === 404) {
          setSongs([]);
          setError("Không tìm thấy bài hát");
        } else if (response.status === 403) {
          // Thêm xử lý khi trạng thái isActive là false
          setSongs([]);
          setError("Tài khoản không hoạt động");
        } else {
          setError("Lỗi không xác định");
        }
      }
    } catch (err) {
      console.error(err);
      setSongs([]);
      setError("Lỗi không xác định");
    } finally {
      setLoading(false);
      setSongName("");
    }
  };
  


  useEffect(() => {
    if (songs.length > 0) {
      navigate(`/dXNlcg==/songs`);
    }
  }, [songs]);


  const handleAuthorNameSubmit = async (event) => {
    event.preventDefault();

    if (!authorName.trim()) {
      setError("Vui lòng nhập tên tác giả");
      setAuthors([]);
      setSongs([]);
      setAuthorWork([]);
      return;
    }

    setLoading(true);

    let requestData = {
      ten_tac_gia: authorName,
      username: username,
    };

    try {
      const response = await fetch("https://artfinder-backend-git-main-nvtrung30796.vercel.app/user/searchAuthorByName", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        const data = await response.json();
        setAuthors(data);
        setError(null);
      } else {
        if (response.status === 404) {
          setAuthors([]);
          setError("Không tìm thấy tác giả");
        } else if (response.status === 403) {
          setAuthors([]);
          setError("Tài khoản không hoạt động");
        } else {
          setError("Lỗi không xác định");
        }
      }
    } catch (error) {
      console.error("Có lỗi xảy ra khi gửi yêu cầu đến server:", error);
      setError("Có lỗi xảy ra khi kết nối đến server.");
    }

    setDisplayType("authors");
    setAuthorName("");
    setLoading(false);
};


  useEffect(() => {
    if (authors.length > 0) {
      navigate(`/dXNlcg==/authors`);
    }
  }, [authors]);




  let result = 0;
  if (displayType === "songs") {
    result = songs.length;
  } else if (displayType === "authors") {
    result = authors.length;
  } else if (displayType === "authorWork") {
    result = authorWork.bai_hat.length;
  }

  return (
    <div className="container-user">

      <div className="sidebar">
        <form onSubmit={handleSongNameSubmit}>
          <label>
            Nhập tên bài hát:
            <input
              type="text"
              value={songName}
              onChange={(event) => setSongName(event.target.value)}
            />
          </label>
          <input type="submit" value="Tìm kiếm" />
        </form>

        <form onSubmit={handleAuthorNameSubmit}>
          <label>
            Nhập tên tác giả:
            <input
              type="text"
              value={authorName}
              onChange={(event) => setAuthorName(event.target.value)}
              className="input-text"
            />
          </label>
          <input type="submit" value="Tìm kiếm" />
        </form>
        <Link to='/user/added-songs' className="added-songs-link">Danh Sách Các Bài Hát <br /> Đã Thêm</Link> 
        <Link to='/user/history' className="history-songs-link">Lịch Sử Hoạt Động</Link> <br />



        {username && (
          <button onClick={handleLogout} className="logout-button">
            Đăng xuất
          </button>
        )}




      </div>

      {/* Display the number of search results at the top of the table */}


      <div className="main-content mt-4">
        {error && <p className="error">{error}</p>}
        {loading &&
          <div class="overlay">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        }

        {showLogoutModal && (
          <div className="logout-modal modal-background">
            <div className="modal-content logout-modal-content">
              <h2 className="logout-modal-title">Xác Nhận Đăng Xuất</h2>
              <p>Bạn có chắc chắn muốn đăng xuất?</p>
              <button onClick={confirmLogout} className="logout-confirm-btn">Xác Nhận</button>
              <button onClick={() => setShowLogoutModal(false)} className="logout-cancel-btn">Hủy</button>
            </div>
          </div>
        )}

        <SongsContext.Provider value={contextValue}>
          <Outlet />
        </SongsContext.Provider>

      </div>
    </div>
  );
}

export default UserPage;
