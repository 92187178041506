import React, { useContext, useState, useEffect } from "react";
import SongsContext from "../components/SongsContext";
import './User/userPage.css'
import { useAddedSongs } from "../components/AddedSongsContext";

function SongsPage() {
  const [error, setError] = useState(null);
  const context = useContext(SongsContext);
  const { songs } = context;
  const {  addedSongs, addSong } = useAddedSongs();

  useEffect(() => {
    let result = songs ? songs.length : 0;
    // console.log("TEST SONGS", songs)
    if (result === 0) {
      setError("Không tìm thấy bài hát");
    } else {
      setError(null);
    }
  }, [songs]);


  const isSongAdded = song => {
    return addedSongs.some(addedSong => addedSong.ma_code_bai_hat === song.ma_code_bai_hat);
  };
  const handleAddClick = (song) => {
    addSong(song);
}

  return (
    <div>
      <p className="result">Số kết quả tìm kiếm: {songs.length}</p>
      {error && <p className="error">{error}</p>}
      {songs && (
        <table>
          <thead>
            <tr>
              <th className="column-stt">STT</th>
              <th className="column-ma">Code</th>
              <th className="column-ten">Tên Bài Hát</th>
              <th>Nhạc</th>
              <th>Lời</th>
              <th>Nhà Xuất Bản</th>
              <th>Trạng Thái</th>
              <th>Note</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {songs.map((song, index) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td className="ma-code">{song.ma_code_bai_hat}</td>
                <td>{Array.isArray(song.ten_bai_hat) ? song.ten_bai_hat.join(', ') : song.ten_bai_hat}</td>
                <td>{song.nhac}</td>
                <td>{song.loi}</td>
                <td>{song.publisher}</td>
                <td>{song.status}</td>
                <td>{song.note}</td>
                <td>
                                {isSongAdded(song) ? 
                                    <button disabled className="btn-added">Đã Thêm</button> : 
                                    <button onClick={() => handleAddClick(song)} className="btn-add">Thêm</button>
                                }
                            </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default SongsPage;

