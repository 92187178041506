import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import './profilePage.css'
import { useNavigate } from "react-router-dom";
import { useAddedSongs } from '../../components/AddedSongsContext';


function ProfilePage() {
    const [decodedData, setDecodedData] = useState(null);
    const [userSongs, setUserSongs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openHistory, setOpenHistory] = useState(null);
    const navigate = useNavigate();
    const { setSongs } = useAddedSongs();
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUserSongs, setFilteredUserSongs] = useState([]);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                let decodedData = jwtDecode(token);
                setDecodedData(decodedData);

                // Lấy danh sách bài hát từ API dựa trên username
                fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/user/history`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username: decodedData.username })
                })
                    .then(res => {
                        if (!res.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return res.json();
                    })
                    .then(data => {
                        // Sắp xếp dữ liệu theo timestamp giảm dần

                        // data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

                         // In ra giá trị của data sau khi sắp xếp
                        setUserSongs(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Failed to fetch songs:", error);
                        setLoading(false);
                    });

            } catch (e) {
                console.error("Failed to decode token:", e);
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    }

    const handleAdjustment = (songs) => {
        // Thay thế toàn bộ danh sách bài hát trong context
        setSongs(songs);

        // Điều hướng tới trang user/added-songs
        navigate('/user/added-songs');
    }

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredUserSongs(userSongs);
        } else {
            const filtered = userSongs.filter(historyItem =>
                historyItem.programName.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUserSongs(filtered);
        }
    }, [searchTerm, userSongs]);

    return (
        <div className="profile-history ">
            <div>
                <button className="go-back-button m-2" onClick={handleGoBack}> &laquo; Quay lại</button>
            </div>

            {loading ? (
                <div className="overlay">
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            ) : (
                decodedData && (
                    <div className="profile-content">
                        <h1 className='text-center p-3'>Lịch Sử Hoạt Động Của Bạn</h1>

                        <div className="content-container">
                            <input
                                type="text"
                                placeholder="Tìm danh sách theo tên chương trình..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="search-input"
                            />
                            {filteredUserSongs.length > 0 ? (
                                filteredUserSongs.map((historyItem, index) => {
                                    // const dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
                                    // const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
                                    // const formattedDate = new Date(historyItem.timestamp).toLocaleDateString('vi-VN', dateOptions);
                                    // const formattedTime = new Date(historyItem.timestamp).toLocaleTimeString('vi-VN', timeOptions);

                                    return (
                                        <div key={index} className="history-section">
                                            <h3 className='history-header' onClick={() => setOpenHistory(openHistory === index ? null : index)}>
                                                <i className={`fas fa-arrow-right ${openHistory === index ? 'open' : ''}`}></i>
                                                Danh sách bài hát của chương trình {historyItem.programName} gửi ngày {historyItem.timestamp}
                                            </h3>
                                            <div className={`history-content ${openHistory === index ? 'history-open' : ''}`}>
                                                {openHistory === index && (
                                                    <>
                                                        <p className='history-note'>Ghi Chú: {historyItem.note}</p>
                                                        <div className="table-and-button-container">
                                                            <table className="history-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="column-stt">STT</th>
                                                                        <th className="column-ma">Code</th>
                                                                        <th className="column-ten-addedsongs">Tên Bài Hát</th>
                                                                        <th className="column-nhac">Nhạc</th>
                                                                        <th className="column-loi">Lời</th>
                                                                        <th>Nhà Xuất Bản</th>
                                                                        <th>Trạng Thái</th>
                                                                        <th>Note</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {historyItem.addedSongs.map((song, songIndex) => (
                                                                        <tr key={song._id || song.ma_code_bai_hat}>
                                                                            <td>{songIndex + 1}</td>
                                                                            <td>{song.ma_code_bai_hat}</td>
                                                                            <td>{song.ten_bai_hat[0]}</td>
                                                                            <td>{song.nhac}</td>
                                                                            <td>{song.loi}</td>
                                                                            <td>{song.publisher}</td>
                                                                            <td>{song.status}</td>
                                                                            <td>{song.note}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            <button className='adjustment-button' onClick={() => handleAdjustment(historyItem.addedSongs)}>Điều chỉnh</button>

                                                        </div>

                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <p className='m-2'>Không có danh sách cần tìm !</p>
                            )}
                        </div>

                    </div>
                )
            )}
        </div>
    );


}

export default ProfilePage;
