import React, { useEffect, useState } from 'react';
import './manageAuthors.css'

function ManageAuthors() {
    const [authors, setAuthors] = useState([]);
    const [searchByName, setSearchByName] = useState('');
    const [searchByCode, setSearchByCode] = useState('');
    const [addNewAuthorPopup, setAddNewAuthorPopup] = useState(false);
    const [newAuthorData, setNewAuthorData] = useState({
        ma_tac_gia: '',
        ten_tac_gia: '',
        but_danh: ''
    });
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [authorIdToDelete, setAuthorIdToDelete] = useState(null);
    const [error, setError] = useState(null);
    const [songWork, setSongWork] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAuthorData(prevData => ({ ...prevData, [name]: value }));
        // console.log('data', newAuthorData)
    };

    const handleAddAuthorClick = () => {
        setAddNewAuthorPopup(true); // Hiện popup

    }


    const handleAddAuthor = async () => {
        if (
            !newAuthorData.ma_tac_gia ||
            !newAuthorData.ten_tac_gia ||
            !newAuthorData.but_danh
        ) {
            alert('Vui lòng điền đầy đủ thông tin cho các trường.');
            return;
        }

        // Lấy dữ liệu từ trường nhập trong giao diện người dùng
        const requestData = {
            ma_tac_gia: newAuthorData.ma_tac_gia,
            ten_tac_gia: newAuthorData.ten_tac_gia,
            but_danh: newAuthorData.but_danh
        };

        try {
            // Gửi dữ liệu bài hát lên server
            const response = await fetch('https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/admin-add-author', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData), // Sử dụng requestData thay vì newSongData
            });

            const data = await response.json();

            if (response.status === 201) {
                console.log('Tác giả đã được thêm mới thành công.');
                // Sau khi thêm thành công, bạn có thể thực hiện các thao tác khác ở đây

                // Đóng popup
                setAddNewAuthorPopup(false);
            } else {
                alert(data.error);
            }
        } catch (error) {
            console.error('Lỗi khi thêm tác giả:', error);
            // Xử lý lỗi (hiển thị thông báo lỗi hoặc thực hiện các thao tác khác nếu cần)
        }
    }

    const handleSearchByName = async (event) => {
        event.preventDefault();
    
        try {
            const response = await fetch("https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/searchAuthorByName", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ten_tac_gia: searchByName }),
            });
    
            if (response.ok) {
                const data = await response.json();
                setError(null);

                if (data.length === 0) {
                    // Không có tác giả nào được tìm thấy
                    setAuthors([]);
                    setError("Không tìm thấy tác giả");
                    setSongWork(null);

                } else {
                    // Có tác giả được tìm thấy
                    setAuthors(data);
                    setSongWork(null);
                }
            } else if (response.status === 404) {
                // Không tìm thấy tác giả
                setAuthors([]);
                setError("Không tìm thấy tác giả");
            } else {
                // Lỗi không xác định
                setError("Lỗi không xác định");
            }
        } catch (error) {
            // Lỗi kết nối đến server
            console.error("Có lỗi xảy ra khi gửi yêu cầu đến server:", error);
            setError("Có lỗi xảy ra khi kết nối đến server.");
        }
    };
    

    const handleSearchByCode = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch("https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/searchAuthorByCode", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ma_tac_gia: searchByCode }),
            });
        
            if (response.ok) {
                const data = await response.json();
                setError(null);

                if (data.length === 0) {
                    // Không có tác giả nào được tìm thấy
                    setAuthors([]);
                    setSongWork(null);

                    setError("Không tìm thấy tác giả");
                } else {
                    // Có tác giả được tìm thấy
                    setAuthors(data);
                    setSongWork(null);
                }
            } else if (response.status === 404) {
                // Không tìm thấy tác giả
                setAuthors([]);
                setError("Không tìm thấy tác giả");
            } else {
                // Lỗi không xác định
                setError("Lỗi không xác định");
            }
        } catch (error) {
            // Lỗi kết nối đến server
            console.error("Có lỗi xảy ra khi gửi yêu cầu đến server:", error);
            setError("Có lỗi xảy ra khi kết nối đến server.");
        }
    }

    const promptDeleteAuthor = (authorId) => {
        setAuthorIdToDelete(authorId);
        // console.log("testAuthorID", authorId)

        setShowConfirmDelete(true);
    };

    const confirmDeleteAuthor = async () => {
        try {
            const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/admin-delete-author/${authorIdToDelete}`, {
                method: 'DELETE'
            });

            const data = await response.json();

            if (response.status === 200) {
                const updatedAuthors = authors.filter(author => author._id !== authorIdToDelete);
                setAuthors(updatedAuthors);

            } else {
                alert(data.message || 'Lỗi khi xóa tác giả.');
            }
        } catch (error) {
            console.error("Error deleting author:", error);
            alert('Lỗi khi xóa tác giả.');
        } finally {
            setShowConfirmDelete(false);
            setAuthorIdToDelete(null);
        }
    };




    const handleAuthorCodeClick = async (authorCode) => {
        try {
            const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/admin/get-songs-by-authorCode/${authorCode}`);


            if (response.ok) {
                const data = await response.json();
                // console.log("data", data)
                setSongWork(data);
            } else {
                console.error("Lỗi khi lấy thông tin chi tiết tác phẩm");
            }
        } catch (err) {
            console.error("Có lỗi xảy ra:", err);
        }
    };



    return (
        <div className="manage-authors-container">
            <h2 className="manage-authors-title">Quản lý tác giả</h2>
            <button className="manage-authors-add-author-button" onClick={handleAddAuthorClick} >Thêm tác giả mới</button>


            {addNewAuthorPopup && (
                <div className="manageauthors-addnewauthor-modal">
                    <div className="manageauthors-addnewauthor-modal-content">
                        <h2 className='manageauthors-addnewauthor-modal-title'>Thêm tác giả mới</h2>
                        <input
                            type="text"
                            name="ma_tac_gia"
                            placeholder="Mã code tác giả"
                            value={newAuthorData.ma_tac_gia}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="ten_tac_gia"
                            placeholder="Tên tác giả"
                            value={newAuthorData.ten_tac_gia}
                            onChange={handleInputChange}
                            required
                        />

                        <input
                            type="text"
                            name="but_danh"
                            value={newAuthorData.but_danh}
                            placeholder="Bút danh"
                            onChange={handleInputChange}
                            required
                        />

                        <button className='addnewauthor-modal-save-btn' onClick={handleAddAuthor}>Lưu</button>
                        <button className='addnewauthor-modal-remove-btn' onClick={() => setAddNewAuthorPopup(false)}>Hủy</button>
                    </div>
                </div>
            )}

            <form onSubmit={handleSearchByName} className="manage-authors-search-form">
                <div className="manage-authors-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo tên tác giả"
                        value={searchByName}
                        onChange={(e) => setSearchByName(e.target.value)}
                    />
                </div>
                <button className="manage-authors-search-button" type="submit">
                    Tìm kiếm
                </button>
            </form>

            <form onSubmit={handleSearchByCode} className="manage-authors-search-form">
                <div className="manage-authors-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo mã code tác giả"
                        value={searchByCode}
                        onChange={(e) => setSearchByCode(e.target.value)}
                    />
                </div>
                <button className="manage-authors-search-button" type="submit">
                    Tìm kiếm
                </button>
            </form>

            {error && <p className="error">{error}</p>}

            {authors && (
                <table className="manage-authors-table">
                    <thead>
                        <tr>
                            <th className="column-stt">STT</th>
                            <th className="column-ma">Code</th>
                            <th className="column-ten">Tên Tác Giả</th>
                            <th>Bút Danh</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {authors.map((author, index) => (
                            <tr key={index}>
                                <td>{index + 1}.</td>
                                <td className="ma-code">{author.ma_tac_gia}</td>
                                <td>{Array.isArray(author.ten_tac_gia) ? author.ten_tac_gia.join(', ') : author.ten_tac_gia}</td>
                                <td>{author.but_danh}</td>
                                <td>
                                    <button className='btn-remove' onClick={() => promptDeleteAuthor(author._id)}>Xóa</button>
                                    <button className="view-works-btn" onClick={() => handleAuthorCodeClick(author.ma_tac_gia)}>
                                        Tác phẩm
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {showConfirmDelete && (
                <div className="confirm-delete-modal">
                    <div className="confirm-delete-modal-content">
                        <h2 className='confirm-delete-modal-title'>Bạn có chắc muốn xóa tác giả này?</h2>
                        <button onClick={confirmDeleteAuthor} className="confirm-delete-modal-confirm-btn">Xác nhận</button>
                        <button onClick={() => setShowConfirmDelete(false)} className="confirm-delete-modal-cancel-button">Hủy</button>
                    </div>
                </div>
            )}

            {songWork && (
                <table className='manage-authors-table-get-songs'>
                    <thead>
                        <tr>
                            <th className="column-stt">STT</th>
                            <th className="column-ma">Code</th>
                            <th className="column-ten">Tên Bài Hát</th>
                            <th>Nhạc</th>
                            <th>Lời</th>
                            <th>Nhà Xuất Bản</th>
                            <th>Trạng Thái</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        {songWork.map((song, index) => (
                            <tr key={index}>
                                <td>{index + 1}.</td>
                                <td className="ma-code">{song.ma_code_bai_hat}</td>
                                <td>{Array.isArray(song.ten_bai_hat) ? song.ten_bai_hat.join(', ') : song.ten_bai_hat}</td>
                                <td>{song.nhac}</td>
                                <td>{song.loi}</td>
                                <td>{song.publisher}</td>
                                <td>{song.status}</td>
                                <td>{song.note}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

        </div>
    );
}

export default ManageAuthors;
