import React, { useState, useEffect } from 'react';
import './adminPage.css';
import { Link, Outlet, useNavigate } from 'react-router-dom';

function AdminPage() {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const navigate = useNavigate();


    const confirmLogout = () => {
        //Xóa token khỏi localStorage
        localStorage.removeItem('token');

        //Chuyển hướng người dùng về trang chính
        navigate("/");

        //Đóng modal đăng xuất
        setShowLogoutModal(false);
    }

    const handleLogout = () => {
        setShowLogoutModal(true);
    };


    return (
        <div className="admin-dashboard">
            <div className="admin-sidebar">
                <div className="admin-menu"> 
                    <h3 className="admin-title">Trang Quản Trị</h3>
                    <hr />
                    <ul>
                        <li>
                            <Link to="users" className="full-width-link">
                                <i className="fas fa-user"></i> Quản lý người dùng
                            </Link>
                        </li>
                        <li>
                            <Link to="songs" className="full-width-link">
                                <i className="fas fa-music"></i> Quản lý tác phẩm
                            </Link>
                        </li>
                        <li>
                            <Link to="authors" className="full-width-link">
                                <i className="fas fa-pen"></i> Quản lý tác giả
                            </Link>
                        </li>
                        <li>
                            <Link to="contracts" className="full-width-link">
                                <i className="fas fa-file-contract"></i> Quản lý hợp đồng (PN)
                            </Link>
                        </li>
                        <li>
                            <Link to="contracts_north" className="full-width-link">
                                <i className="fas fa-file-contract"></i> Quản lý hợp đồng (PB)
                            </Link>
                        </li>
                        <li>
                            <Link to="youtube_checklist" className="full-width-link">
                                <i className="fas fa-tasks"></i> Quản lý Danh Sách Đối Chiếu
                            </Link>
                        </li>
                    </ul>
                </div>

                <button onClick={handleLogout} className="logout-button">
                    Đăng xuất
                </button>
            </div>

            <div className="admin-content">
                <Outlet />
            </div>
            {showLogoutModal && (
                <div className="logout-modal modal-background">
                    <div className="modal-content logout-modal-content">
                        <h2 className="logout-modal-title">Xác Nhận Đăng Xuất</h2>
                        <p>Bạn có chắc chắn muốn đăng xuất?</p>
                        <button onClick={confirmLogout} className="logout-confirm-btn">Xác Nhận</button>
                        <button onClick={() => setShowLogoutModal(false)} className="logout-cancel-btn">Hủy</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AdminPage;

