import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "./loginPage.css";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import jwtDecode from 'jwt-decode';

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { setUser } = useUser();


  const goToHomePage = async (event) => {
    event.preventDefault();
    setUsernameError('');
    setPasswordError('');
  
    const response = await fetch("https://artfinder-backend-git-main-nvtrung30796.vercel.app/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ username, password })
    });
  
    const data = await response.json();
  
    if (response.status === 200) {
      localStorage.setItem("token", data.token);  // Lưu token

      // Giải mã token
    const decodedToken = jwtDecode(data.token); 
      // Log token để kiểm tra

      console.log(`Logged in as: ${decodedToken.username}, Email: ${decodedToken.email}`); 
       // Sử dụng thông tin từ token đã giải mã
      setUser(decodedToken);

      if (decodedToken.isAdmin) {
        navigate("/YWRtaW4=");
      } else {
        navigate("/dXNlcg==");
      }
    } else {
      setUsernameError(data.error);
      setPasswordError(data.error);
    }
  };
  
  

  return (
    <div className="container-login">
      <div className="d-flex align-items-center justify-content-center mt-5 form-container">
        <Form onSubmit={goToHomePage}>
          <h2 className="login-title">Đăng Nhập</h2>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Tên đăng nhập</Form.Label>
            <Form.Control
              type="text"
              placeholder="Username"
              onChange={e => {
                setUsername(e.target.value);
                setUsernameError(''); // Clear error when user changes input
              }} />
            <Form.Text className="text-danger">
              {usernameError}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Mật khẩu</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={e => {
                setPassword(e.target.value);
                setPasswordError(''); // Clear error when user changes input
              }} />
            <Form.Text className="text-danger">
              {passwordError}
            </Form.Text>
          </Form.Group>
          <button className="w-100 btn-login" type="submit">
            ĐĂNG NHẬP
          </button>
        </Form>
      </div>
    </div>
  );
}

export default LoginPage;
