import React, { createContext, useState, useContext, useEffect } from 'react';

const AddedSongsContext = createContext();

export const useAddedSongs = () => {
  return useContext(AddedSongsContext);
};






export const AddedSongsProvider = ({ children }) => {
  // Lấy dữ liệu từ localStorage
  const initialSongs = JSON.parse(localStorage.getItem('addedSongs') || '[]');
  const [addedSongs, setAddedSongs] = useState(initialSongs);

  useEffect(() => {
    // Lưu dữ liệu vào localStorage mỗi khi addedSongs thay đổi
    localStorage.setItem('addedSongs', JSON.stringify(addedSongs));
  }, [addedSongs]);

  const addSong = (song) => {
    setAddedSongs(prevSongs => [...prevSongs, song]);
  };

  const removeSong = (songIndex) => {
    setAddedSongs(prevSongs => prevSongs.filter((_, index) => index !== songIndex));
  };

  const resetSongs = () => {
    setAddedSongs([]);
  };

  const setSongs = (songs) => {
    setAddedSongs(songs);
};

  return (
    <AddedSongsContext.Provider value={{ addedSongs, addSong, removeSong, resetSongs, setSongs }}>
      {children}
    </AddedSongsContext.Provider>
  );
};


export default AddedSongsContext;
