import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./User/userPage.css";
import { useAddedSongs } from "../components/AddedSongsContext";


function AuthorDetailPage() {
  const { authorCode } = useParams();
  const [authorWork, setauthorWork] = useState(null);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false); // Thêm biến trạng thái 
  const [loading, setLoading] = useState(false);
  const [songCount, setSongCount] = useState(0);
  const { addedSongs, addSong } = useAddedSongs();

  useEffect(() => {
    const fetchAuthorDetail = async () => {
      setLoading(true);

      try {
        // const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/api/songs/${authorCode}`);
        const response = await fetch(`https://artfinder-backend-git-main-nvtrung30796.vercel.app/user/api/songs/${authorCode}`);

        if (response.ok) {
          const data = await response.json();
          setauthorWork(data);
          setIsLoaded(true); // Cập nhật trạng thái sau khi dữ liệu được tải
          setLoading(false);

          // Cập nhật số lượng bài hát từ dữ liệu authorWork
          const count = data.bai_hat.length;
          setSongCount(count);
        } else {
          console.error("Lỗi khi lấy thông tin chi tiết tac gia");
        }
      } catch (err) {
        console.error("Có lỗi xảy ra:", err);
      }
    };

    if (!isLoaded) { // Chỉ gọi API nếu dữ liệu chưa được tải
      fetchAuthorDetail();
    }
  }, [authorCode, isLoaded]); // Thêm isLoaded vào danh sách phụ thuộc

  const showSongDetails = (songCode) => {
    console.log("MABAIHAT", songCode)
    navigate(`/user/songs/${songCode}`);
  }

  const isSongAdded = song => {
    return addedSongs.some(addedSong => addedSong.ma_code_bai_hat === song.ma_code_bai_hat);
  };

  const handleAddClick = (song) => {
    addSong(song);
  }

  const handleGoBack = () => {
    navigate(-1); 
  }

  return (
    <div>
      <div>
        <button className="go-back-button" onClick={handleGoBack}> &laquo; Quay lại</button>
      </div>
      <p className="result">Số kết quả tìm kiếm:  {songCount}</p>
      {loading &&
        <div class="overlay">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      {authorWork && (
        <table>
          <thead>
            <tr>
              <th className="column-stt">STT</th>
              <th className="column-ma">Code</th>
              <th className="column-tentg">Tên Tác Giả</th>
              <th className="column-tenthat">Bút Danh</th>
              <th className="column-tacpham">Tác phẩm</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.</td>
              <td>{authorWork.ma_tac_gia}</td>
              <td>{authorWork.ten_tac_gia}</td>
              <td>
                {Array.isArray(authorWork.but_danh) ?
                  authorWork.but_danh.map((but_danh, index) => (
                    <p key={index}>{but_danh}</p>
                  ))
                  : <p>{authorWork.but_danh}</p>
                }
              </td>
              <td>
                {authorWork.bai_hat.map((song, index) => (
                  <div className="song-group" key={index}>
                    {song.ten_bai_hat.map((title, subIndex) => (
                      <p className="song-title" key={subIndex}>{title}</p>
                    ))}
                    <div className="buttons-wrapper">
                      {
                        isSongAdded(song)
                          ? <button className='btn-added' disabled>Đã Thêm</button>
                          : <button className='btn-add' onClick={() => handleAddClick(song)}>Thêm</button>
                      }
                      <button className='btn-detail' onClick={() => showSongDetails(song.ma_code_bai_hat)}>Chi tiết</button>
                    </div>

                  </div>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      )}

    </div>
  );



}

export default AuthorDetailPage;
