import React, { useState } from 'react';
import './manageContracts.css';

function ManagContracts() {
    const [contracts, setContracts] = useState([]);
    const [searchByContractNumber, setSearchByContractNumber] = useState('');
    const [searchByIdLink, setSearchByIdLink] = useState('');
    const [searchBySongCode, setSearchBySongCode] = useState('');
    const [searchBySongName, setSearchBySongName] = useState('');
    const [searchByAuthorName, setSearchByAuthorName] = useState('');
    const [searchByProgramName, setSearchByProgramName] = useState('');
    const [searchByIdChannel, setSearchByIdChannel] = useState('');

    const [error, setError] = useState(null);
    const [addNewContractPopup, setAddNewContractPopup] = useState(false);
    const [newContractData, setNewContractData] = useState({
        linh_vuc: '',
        so_hop_dong: '',
        so_pl: '',
        ngay_ky: '',
        ten_kenh_Chuong_trinh: '',
        link_kenh: '',
        id_link: '',
        tac_pham: '',
        tac_gia: '',
        bat_dau: '',
        ket_thuc: '',
        hinh_thuc: '',
        muc_nhuan_bút_no_vat: '',
        ghi_chu: '',
        nguoi_thuc_hien: '',
        mail: '',
        code: ""
    });

    const [contractIdToDelete, setContractIdToDelete] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [contractIdToModify, setContractIdToModify] = useState(null);
    const [showConfirmModify, setShowConfirmModify] = useState(false);
    const [modifyContractData, setModifyContractData] = useState({
        linh_vuc: '',
        so_hop_dong: '',
        so_pl: '',
        ngay_ky: '',
        ten_kenh_Chuong_trinh: '',
        link_kenh: '',
        id_link: '',
        tac_pham: '',
        tac_gia: '',
        bat_dau: '',
        ket_thuc: '',
        hinh_thuc: '',
        muc_nhuan_bút_no_vat: '',
        ghi_chu: '',
        nguoi_thuc_hien: '',
        mail: '',
        code: ""
    })
    const [loading, setLoading] = useState(false);





    const handleSearch = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('https://artfinder-backend.vercel.app/admin/searchContract', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ so_hop_dong: searchByContractNumber, id_link: searchByIdLink, song_code: searchBySongCode, song_name: searchBySongName, author_name: searchByAuthorName, program_name: searchByProgramName, id_kenh: searchByIdChannel })
            });

            if (response.ok) {
                const data = await response.json();
                setError(null);
                setLoading(false);

                if (data.length === 0) {
                    // Không tìm thấy hợp đồng nào
                    setContracts([]);
                    setError("Không tìm thấy hợp đồng");
                } else {
                    // Có hợp đồng được tìm thấy
                    setContracts(data);
                    setError(null); // Đặt lại lỗi thành null khi có dữ liệu hợp lệ
                    // setSearchByContractNumber("");
                    // setSearchByIdLink("");
                    // setSearchBySongCode("");
                    // setSearchBySongName("");
                }
            } else if (response.status === 404) {
                // Không tìm thấy hợp đồng
                setContracts([]);
                setError("Không tìm thấy hợp đồng");
            } else {
                // Lỗi không xác định
                setError("Lỗi không xác định");
            }
        } catch (error) {
            // Lỗi kết nối đến server
            console.error("Có lỗi xảy ra khi gửi yêu cầu đến server:", error);
            setError("Có lỗi xảy ra khi kết nối đến server.");
        }
    };

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    }

    const handleAddContractClick = () => {
        setAddNewContractPopup(true) // Hiện popup
    }

    const handleAddContract = async () => {
        // Kiểm tra các trường bắt buộc trước khi gửi yêu cầu
        if (
            !newContractData.linh_vuc ||
            !newContractData.so_hop_dong ||
            !newContractData.so_pl ||
            !newContractData.ngay_ky ||
            !newContractData.ten_kenh_Chuong_trinh ||
            !newContractData.link_kenh ||
            !newContractData.id_link ||
            !newContractData.code ||
            !newContractData.tac_pham ||
            !newContractData.tac_gia ||
            !newContractData.bat_dau ||
            !newContractData.ket_thuc ||
            !newContractData.hinh_thuc ||
            !newContractData.muc_nhuan_bút_no_vat ||
            // !newContractData.nguoi_thuc_hien ||
            !newContractData.mail

        ) {
            alert('Vui lòng điền đầy đủ thông tin cho các trường.');
            return;
        }

        // Lấy dữ liệu từ trường nhập trong giao diện người dùng
        const requestData = {
            linh_vuc: newContractData.linh_vuc,
            so_hop_dong: newContractData.so_hop_dong,
            so_pl: newContractData.so_pl,
            ngay_ky: newContractData.ngay_ky,
            ten_kenh_Chuong_trinh: newContractData.ten_kenh_Chuong_trinh,
            link_kenh: newContractData.link_kenh,
            id_link: newContractData.id_link,
            code: newContractData.code,
            tac_pham: newContractData.tac_pham,
            tac_gia: newContractData.tac_gia,
            bat_dau: newContractData.bat_dau,
            ket_thuc: newContractData.ket_thuc,
            hinh_thuc: newContractData.hinh_thuc,
            muc_nhuan_bút_no_vat: newContractData.muc_nhuan_bút_no_vat,
            ghi_chu: newContractData.ghi_chu,
            // nguoi_thuc_hien: newContractData.nguoi_thuc_hien,
            mail: newContractData.mail
        };

        try {
            // Gửi dữ liệu bài hát lên server
            const response = await fetch('https://artfinder-backend.vercel.app/admin/admin-add-contract', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();

            if (response.status === 201) {
                console.log('Hợp đồng đã được thêm mới thành công.');
                // Sau khi thêm thành công, bạn có thể thực hiện các thao tác khác ở đây

                // Đóng popup
                setAddNewContractPopup(false);
            } else {
                alert(data.error);
            }
        } catch (error) {
            console.error('Lỗi khi thêm hợp đồng:', error);
            // Xử lý lỗi (hiển thị thông báo lỗi hoặc thực hiện các thao tác khác nếu cần)
        }

    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewContractData(prevData => ({ ...prevData, [name]: value }));
        // console.log('data', newSongData)
    };

    const handleModifyInputChange = (e) => {
        const { name, value } = e.target;
        setModifyContractData(prevData => ({ ...prevData, [name]: value }));
        // console.log('data', newSongData)
    }


    const promptDeleteContract = (contractId) => {
        setContractIdToDelete(contractId);
        // console.log("testContractID", contractId)

        setShowConfirmDelete(true);

    };

    const promptModifyContract = (contractId) => {
        // console.log("testContractID", contractId)

        const contractToModify = contracts.find(contract => contract._id === contractId);
        // Cập nhật trạng thái modifyContractData với thông tin của hợp đồng
        setContractIdToModify(contractToModify._id);
        // console.log("test-id", contractIdToModify._id)
        // Hiển thị popup chỉnh sửa
        setModifyContractData({
            linh_vuc: contractToModify.linh_vuc,
            so_hop_dong: contractToModify.so_hop_dong,
            so_pl: contractToModify.so_pl,
            ngay_ky: contractToModify.ngay_ky,
            ten_kenh_Chuong_trinh: contractToModify.ten_kenh_Chuong_trinh,
            link_kenh: contractToModify.link_kenh,
            id_link: contractToModify.id_link,
            code: contractToModify.code,
            tac_pham: contractToModify.tac_pham,
            tac_gia: contractToModify.tac_gia,
            bat_dau: contractToModify.bat_dau,
            ket_thuc: contractToModify.ket_thuc,
            hinh_thuc: contractToModify.hinh_thuc,
            muc_nhuan_bút_no_vat: contractToModify.muc_nhuan_bút_no_vat,
            ghi_chu: contractToModify.ghi_chu,
            mail: contractToModify.mail
        });
        setShowConfirmModify(true);
    }

    const confirmDeleteContract = async () => {
        try {
            const response = await fetch(`https://artfinder-backend.vercel.app/admin/admin-delete-contract/${contractIdToDelete}`, {
                method: 'DELETE'
            });

            const data = await response.json();

            if (response.status === 200) {
                const updatedContracts = contracts.filter(contract => contract._id !== contractIdToDelete);
                setContracts(updatedContracts);

                // console.log("successfully")
                // Cập nhật lại danh sách người dùng sau khi xóa

            } else {
                alert(data.message || 'Lỗi khi xóa hợp đồng.');
            }
        } catch (error) {
            console.error("Error deleting contract:", error);
            alert('Lỗi khi xóa hợp đồng.');
        }
        setShowConfirmDelete(false);
        setContractIdToDelete(null);
    }

    const confirmModifyContract = async () => {
        try {
            // Gửi yêu cầu PUT đến API backend để cập nhật hợp đồng
            const response = await fetch(`https://artfinder-backend.vercel.app/admin/admin-edit-contracts/${contractIdToModify}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(modifyContractData),
            });

            if (response.ok) {
                // Nếu cập nhật thành công, cập nhật trực tiếp danh sách hợp đồng
                setContracts(prevContracts => {
                    // Tạo một bản sao mới của danh sách hợp đồng với hợp đồng cập nhật
                    const updatedContracts = prevContracts.map(contract => {
                        if (contract._id === contractIdToModify) {
                            return {
                                ...contract,
                                linh_vuc: modifyContractData.linh_vuc,
                                so_hop_dong: modifyContractData.so_hop_dong,
                                so_pl: modifyContractData.so_pl,
                                ngay_ky: modifyContractData.ngay_ky,
                                ten_kenh_Chuong_trinh: modifyContractData.ten_kenh_Chuong_trinh,
                                link_kenh: modifyContractData.link_kenh,
                                id_link: modifyContractData.id_link,
                                code: modifyContractData.code,
                                tac_pham: modifyContractData.tac_pham,
                                tac_gia: modifyContractData.tac_gia,
                                bat_dau: modifyContractData.bat_dau,
                                ket_thuc: modifyContractData.ket_thuc,
                                hinh_thuc: modifyContractData.hinh_thuc,
                                muc_nhuan_bút_no_vat: modifyContractData.muc_nhuan_bút_no_vat,
                                ghi_chu: modifyContractData.ghi_chu,
                                mail: modifyContractData.mail
                                // Cập nhật các trường dữ liệu khác tương tự
                            };
                        }
                        return contract;
                    });
                    return updatedContracts;
                });

                // Hiển thị thông báo
                alert('Cập nhật thành công');
                setShowConfirmModify(false);
            } else {
                // Nếu có lỗi xảy ra, hiển thị thông báo lỗi
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error('Lỗi:', error);
            alert('Có lỗi xảy ra');
        }
        setContractIdToModify(null);

    };


    return (
        <div className="manage-contracts-container">
            <h2 className="manage-contracts-title">Quản lý hợp đồng (PN)</h2>
            <button className="manage-contracts-add-contract-button" onClick={handleAddContractClick} >Thêm hợp đồng mới</button>

            {addNewContractPopup && (
                <div className="managecontracts-addnewcontract-modal">
                    <div className="managecontracts-addnewcontract-modal-content">
                        <h2 className='managecontracts-addnewcontract-modal-title'>Thêm hợp đồng mới</h2>
                        <div className="input-fields">
                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="linh_vuc"
                                    placeholder="Lĩnh vực"
                                    value={newContractData.linh_vuc}
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="so_hop_dong"
                                    placeholder="Số hợp đồng"
                                    value={newContractData.so_hop_dong}
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="so_pl"
                                    value={newContractData.so_pl}
                                    placeholder="Số phụ lục"
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="ngay_ky"
                                    value={newContractData.ngay_ky}
                                    placeholder="Ngày ký"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="ten_kenh_Chuong_trinh"
                                    value={newContractData.ten_kenh_Chuong_trinh}
                                    placeholder="Tên Kênh Chương trình"
                                    onChange={handleInputChange}
                                />
                                <input
                                    type="text"
                                    name="link_kenh"
                                    value={newContractData.link_kenh}
                                    placeholder="Link Kênh"
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="id_link"
                                    value={newContractData.id_link}
                                    placeholder="ID Link"
                                    onChange={handleInputChange}

                                />
                                <input
                                    type="text"
                                    name="tac_pham"
                                    value={newContractData.tac_pham}
                                    placeholder="Tác phẩm"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="code"
                                    value={newContractData.code}
                                    placeholder="Code"
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="tac_gia"
                                    value={newContractData.tac_gia}
                                    placeholder="Tác giả"
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="bat_dau"
                                    value={newContractData.bat_dau}
                                    placeholder="Bắt đầu"
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="ket_thuc"
                                    value={newContractData.ket_thuc}
                                    placeholder="Kết thúc"
                                    onChange={handleInputChange}
                                    required
                                />

                            </div>

                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="hinh_thuc"
                                    value={newContractData.hinh_thuc}
                                    placeholder="Hình thức"
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="muc_nhuan_bút_no_vat"
                                    value={newContractData.muc_nhuan_bút_no_vat}
                                    placeholder="Mức nhuận bút no VAT"
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="ghi_chu"
                                    value={newContractData.ghi_chu}
                                    placeholder="Ghi chú"
                                    onChange={handleInputChange}
                                    required
                                />

                                <input
                                    type="text"
                                    name="mail"
                                    value={newContractData.mail}
                                    placeholder="Mail"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="button-container">
                                <button className='addnewcontract-modal-save-btn' onClick={handleAddContract}>Lưu</button>
                                <button className='addnewcontract-modal-remove-btn' onClick={() => setAddNewContractPopup(false)}>Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>

            )}





            {/* SEARCH FORM */}
            <form onSubmit={handleSearch} className="manage-contracts-search-form">
                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo số hợp đồng"
                        value={searchByContractNumber}
                        onChange={(e) => setSearchByContractNumber(e.target.value)}
                    />
                </div>

                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo tên chương trình"
                        value={searchByProgramName}
                        onChange={(e) => setSearchByProgramName(e.target.value)}
                    />
                </div>

                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo tên ID Kênh"
                        value={searchByIdChannel}
                        onChange={(e) => setSearchByIdChannel(e.target.value)}
                    />
                </div>

                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo ID Link"
                        value={searchByIdLink}
                        onChange={(e) => setSearchByIdLink(e.target.value)}
                    />
                </div>

                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo Code"
                        value={searchBySongCode}
                        onChange={(e) => setSearchBySongCode(e.target.value)}
                    />
                </div>

                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo tên tác phẩm"
                        value={searchBySongName}
                        onChange={(e) => setSearchBySongName(e.target.value)}
                    />
                </div>

                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo tên tác giả"
                        value={searchByAuthorName}
                        onChange={(e) => setSearchByAuthorName(e.target.value)}
                    />
                </div>
                <button className="manage-contracts-search-button" type="submit">
                    Tìm kiếm
                </button>
            </form>






            {loading &&
        <div class="overlay">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
            {/* ERROR INFO */}
            {error && <p className="error">{error}</p>}



            {/* CONTRACT INFO */}
            {contracts && (
                <div>
                    <p className='total-contract'>Kết quả tìm được: {contracts.length}</p>

                    <table className="manage-contracts-table">
                        <thead>
                            <tr>
                                <th className="column-stt">STT</th>
                                <th>Lĩnh vực</th>
                                <th>Số hợp đồng</th>
                                <th>Số phụ lục</th>
                                <th className='column-date'>Ngày ký</th>
                                <th>Tên Kênh - Chương Trình</th>
                                <th>ID Kênh</th>
                                <th>ID Link</th>
                                <th>Code</th>
                                <th>Tác phẩm</th>
                                <th>Tác giả</th>
                                <th className='column-date'>Bắt đầu</th>
                                <th className='column-date'>Kết thúc</th>
                                <th>Ghi chú</th>
                                <th>Người thực hiện</th>
                                <th>Hành động</th>

                            </tr>
                        </thead>
                        <tbody>
                            {contracts.map((contract, index) => (
                                <tr key={index}>
                                    <td>{index + 1}.</td>
                                    <td>{contract.linh_vuc}</td>
                                    <td>{contract.so_hop_dong}</td>
                                    <td>{contract.so_pl}</td>
                                    <td>{formatDate(contract.ngay_ky)}</td> {/* Định dạng lại ngày ký */}
                                    <td>{contract.kenh_chuong_trinh}</td>
                                    <td>
                                        {contract.id_kenh}
                                    </td>
                                    <td>{contract.id_link}</td>
                                    <td>{contract.code}</td>
                                    <td>{contract.tac_pham}</td>
                                    <td>{contract.tac_gia}</td>
                                    <td>{formatDate(contract.bat_dau)}</td>
                                    <td>{formatDate(contract.ket_thuc)}</td>
                                    <td>{contract.ghi_chu}</td>
                                    <td>{contract.nguoi_thuc_hien}</td>
                                    <td className='btn-container'>
                                        <button disabled className='btn-remove' onClick={() => promptDeleteContract(contract._id)}>Xóa</button>
                                        <button disabled className='btn-modify' onClick={() => promptModifyContract(contract._id)}>Điều chỉnh</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            )}

            {showConfirmDelete && (
                <div className="confirm-delete-modal">
                    <div className="confirm-delete-modal-content">
                        <h2 className='confirm-delete-modal-title'>Bạn có chắc muốn xóa mục này?</h2>
                        <button onClick={confirmDeleteContract} className="confirm-delete-modal-confirm-btn">Xác nhận</button>
                        <button onClick={() => setShowConfirmDelete(false)} className="confirm-delete-modal-cancel-button">Hủy</button>
                    </div>
                </div>
            )}


            {showConfirmModify && (
                <div className="managecontracts-modifycontract-modal">
                    <div className="managecontracts-modifycontract-modal-content">
                        <h2 className='managecontracts-modifycontract-modal-title'>Chỉnh sửa hợp đồng</h2>
                        <div className="input-fields">
                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="linh_vuc"
                                    value={modifyContractData.linh_vuc}
                                    onChange={handleModifyInputChange}
                                />
                                <input
                                    type="text"
                                    name="so_hop_dong"
                                    placeholder="Số hợp đồng"
                                    value={modifyContractData.so_hop_dong}
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="so_pl"
                                    value={modifyContractData.so_pl}
                                    placeholder="Số phụ lục"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="ngay_ky"
                                    value={modifyContractData.ngay_ky}
                                    placeholder="Ngày ký"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                            </div>
                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="ten_kenh_Chuong_trinh"
                                    value={modifyContractData.ten_kenh_Chuong_trinh}
                                    placeholder="Tên Kênh Chương trình"
                                    onChange={handleModifyInputChange}
                                />
                                <input
                                    type="text"
                                    name="link_kenh"
                                    value={modifyContractData.link_kenh}
                                    placeholder="Link Kênh"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="id_link"
                                    value={modifyContractData.id_link}
                                    placeholder="ID Link"
                                    onChange={handleModifyInputChange}

                                />
                                <input
                                    type="text"
                                    name="tac_pham"
                                    value={modifyContractData.tac_pham}
                                    placeholder="Tác phẩm"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                            </div>

                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="code"
                                    value={modifyContractData.code}
                                    placeholder="Code"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="tac_gia"
                                    value={modifyContractData.tac_gia}
                                    placeholder="Tác giả"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="bat_dau"
                                    value={modifyContractData.bat_dau}
                                    placeholder="Bắt đầu"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="ket_thuc"
                                    value={modifyContractData.ket_thuc}
                                    placeholder="Kết thúc"
                                    onChange={handleModifyInputChange}
                                    required
                                />

                            </div>
                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="hinh_thuc"
                                    value={modifyContractData.hinh_thuc}
                                    placeholder="Hình thức"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="muc_nhuan_bút_no_vat"
                                    value={modifyContractData.muc_nhuan_bút_no_vat}
                                    placeholder="Mức nhuận bút no VAT"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="ghi_chu"
                                    value={modifyContractData.ghi_chu}
                                    placeholder="Ghi chú"
                                    onChange={handleModifyInputChange}
                                    required
                                />

                                <input
                                    type="text"
                                    name="mail"
                                    value={modifyContractData.mail}
                                    placeholder="Mail"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                            </div>

                            <div className="button-container">
                                <button className='modifycontract-modal-save-btn' onClick={confirmModifyContract}>Lưu</button>
                                <button className='modifycontract-modal-cancel-btn' onClick={() => setShowConfirmModify(false)}>Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default ManagContracts;
