import React, { useState } from 'react';
import './manageYotubeCheckList.css';
import moment from 'moment';
import * as XLSX from 'xlsx';



function ManagYoutubeCheckList() {
    const [contracts, setContracts] = useState([]);
    const [channelInfo, setChannelInfo] = useState([]);

    const [searchByContractNumber, setSearchByContractNumber] = useState('');
    const [searchByProgramName, setSearchByProgramName] = useState('');
    const [searchByIdChannel, setSearchByIdChannel] = useState('');

    const [error, setError] = useState(null);
    const [addNewContractPopup, setAddNewContractPopup] = useState(false);
    const [newContractData, setNewContractData] = useState({
        tinh_trang: '',
        so_hop_dong: '',
        so_pl: '',
        linh_vuc: '',
        ngay_ky: '',
        ten_kenh_Chuong_trinh: '',
        link_kenh: '',
        nguoi_thuc_hien: '',
        ghi_chu: ''
    });

    const [contractIdToDelete, setContractIdToDelete] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [contractIdToModify, setContractIdToModify] = useState(null);
    const [showConfirmModify, setShowConfirmModify] = useState(false);
    const [modifyContractData, setModifyContractData] = useState({
        TINH_TRANG: '',
        SO_HOP_DONG: '',
        SO_PHU_LUC: '',
        LINH_VUC: '',
        NGAY_KY: '',
        TEN_KENH: '',
        ID_KENH: '',
        NGUOI_THUC_HIEN: '',
        GHI_CHU: '',
        userCode: ''
    })
    const [loading, setLoading] = useState(false);

    const [contractIdToViewHistory, setContractIdToViewHistory] = useState(null);
    const [historyData, setHistoryData] = useState([]);
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    const expectedColumns = [
        "tinh_trang",
        "so_hop_dong",
        "so_pl",
        "linh_vuc",
        "ngay_ky",
        "ten_kenh_Chuong_trinh",
        "link_kenh",
        "nguoi_thuc_hien",
        "ghi_chu"
    ];

    const [isIndividualTab, setIsIndividualTab] = useState(true)
    const [jsonData, setJsonData] = useState(null);

    const handleSearch = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('https://artfinder-backend.vercel.app/admin/searchChannelInfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id_kenh: searchByIdChannel, so_hop_dong: searchByContractNumber, program_name: searchByProgramName })
            });

            if (response.ok) {
                const data = await response.json();
                setError(null);
                setLoading(false);

                if (data.length === 0) {
                    // Không tìm thấy hợp đồng nào
                    setChannelInfo([]);
                    setError("Không tìm thấy thông tin");
                } else {
                    // Có hợp đồng được tìm thấy
                    setChannelInfo(data);
                    setError(null); // Đặt lại lỗi thành null khi có dữ liệu hợp lệ
                    // setSearchByContractNumber("");
                    // setSearchByIdLink("");
                    // setSearchBySongCode("");
                    // setSearchBySongName("");
                }
            } else if (response.status === 404) {
                // Không tìm thấy hợp đồng
                setChannelInfo([]);
                setError("Không tìm thấy thông tin");
            } else {
                // Lỗi không xác định
                setError("Lỗi không xác định");
            }
        } catch (error) {
            // Lỗi kết nối đến server
            console.error("Có lỗi xảy ra khi gửi yêu cầu đến server:", error);
            setError("Có lỗi xảy ra khi kết nối đến server.");
        }
    };


    function formatDate(dateString) {
        if (!dateString) {
            // Xử lý trường hợp dateString là null hoặc undefined
            return '';
        }

        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    }

    const handleAddContractClick = () => {
        setAddNewContractPopup(true) // Hiện popup
    }

    const handleAddChannelInfo = async () => {
        // Kiểm tra các trường bắt buộc trước khi gửi yêu cầu
        if (!newContractData.link_kenh && !newContractData.nguoi_thuc_hien) {
            alert('Vui lòng điền thông tin Link Kênh và Người Thực Hiện.');
            return;
        } else if (!newContractData.link_kenh) {
            alert('Vui lòng điền thông tin Link Kênh.');
            return;
        } else if (!newContractData.nguoi_thuc_hien) {
            alert('Vui lòng điền thông tin Người Thực Hiện.');
            return;
        }

        // Lấy dữ liệu từ trường nhập trong giao diện người dùng
        const requestData = {
            tinh_trang: newContractData.tinh_trang,
            so_hop_dong: newContractData.so_hop_dong,
            so_pl: newContractData.so_pl,
            linh_vuc: newContractData.linh_vuc,
            ngay_ky: newContractData.ngay_ky,
            ten_kenh_Chuong_trinh: newContractData.ten_kenh_Chuong_trinh,
            link_kenh: newContractData.link_kenh,
            nguoi_thuc_hien: newContractData.nguoi_thuc_hien,
            ghi_chu: newContractData.ghi_chu,
        };

        try {
            setLoading(true); 

            // Gửi dữ liệu bài hát lên server
            const response = await fetch('https://artfinder-backend.vercel.app/admin/admin-add-channelInfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();

            if (response.status === 201) {
                alert('Thông tin đã được thêm mới thành công.');
                // Sau khi thêm thành công, bạn có thể thực hiện các thao tác khác ở đây

                // Đóng popup
                setAddNewContractPopup(false);
            } else {
                alert(data.error);
            }
        } catch (error) {
            console.error('Lỗi khi thêm thông tin:', error);
            // Xử lý lỗi (hiển thị thông báo lỗi hoặc thực hiện các thao tác khác nếu cần)
        } finally {
            setLoading(false);
        }

    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewContractData(prevData => ({ ...prevData, [name]: value }));
        // console.log('data', newSongData)
    };

    const handleModifyInputChange = (e) => {
        const { name, value } = e.target;
        setModifyContractData(prevData => ({ ...prevData, [name]: value }));
        // console.log('data', newSongData)
    }


    const promptDeleteContract = (contractId) => {
        setContractIdToDelete(contractId);
        // console.log("testContractID", contractId)

        setShowConfirmDelete(true);

    };

    const promptViewContractHistory = async (contractId) => {
        setContractIdToViewHistory(contractId);
        setLoading(true);

        // console.log("testContractID", contractId);

        try {
            const response = await fetch(`https://artfinder-backend.vercel.app/admin/view-channel-history/${contractId}`);
            if (!response.ok) {
                const errorMessage = await response.json();
                alert(errorMessage.message);
                setLoading(false);

                return;
            }

            const historyData = await response.json();
            setHistoryData(historyData);
            setLoading(false);
            setShowHistoryModal(true);
        } catch (error) {
            console.error('Lỗi:', error);
            alert('Có lỗi xảy ra khi lấy lịch sử');
            setLoading(false);


        }
    };

    const promptModifyContract = (contractId) => {
        // console.log("testContractID", contractId);

        const contractToModify = channelInfo.find(contract => {
            return contract._id === contractId;
        });

        // Set contractIdToModify và sau đó log nó
        setContractIdToModify(contractToModify._id);
        // console.log("Updated contractIdToModify:", contractToModify);

        // Hiển thị popup chỉnh sửa
        setModifyContractData({
            TINH_TRANG: contractToModify.TINH_TRANG,
            SO_HOP_DONG: contractToModify.SO_HOP_DONG,
            SO_PHU_LUC: contractToModify.SO_PHU_LUC,
            LINH_VUC: contractToModify.LINH_VUC,
            NGAY_KY: contractToModify.NGAY_KY,
            TEN_KENH: contractToModify.TEN_KENH,
            ID_KENH: contractToModify.ID_KENH,
            NGUOI_THUC_HIEN: contractToModify.NGUOI_THUC_HIEN,
            GHI_CHU: contractToModify.GHI_CHU,
        });
        setShowConfirmModify(true);
    };






    const confirmDeleteContract = async () => {
        try {

            const response = await fetch(`https://artfinder-backend.vercel.app/admin/admin-delete-contract/${contractIdToDelete}`, {
                method: 'DELETE'
            });

            const data = await response.json();

            if (response.status === 200) {
                const updatedContracts = contracts.filter(contract => contract._id !== contractIdToDelete);
                setContracts(updatedContracts);

                // console.log("successfully")
                // Cập nhật lại danh sách người dùng sau khi xóa

            } else {
                alert(data.message || 'Lỗi khi xóa hợp đồng.');
            }
        } catch (error) {
            console.error("Error deleting contract:", error);
            alert('Lỗi khi xóa hợp đồng.');
        }
        setShowConfirmDelete(false);
        setContractIdToDelete(null);
    }

    const confirmModifyContract = async () => {
        // console.log("contractIdToModify:", contractIdToModify);
        try {
            // Kiểm tra trường thông tin mã định danh
            if (!modifyContractData.userCode) {
                alert('Vui lòng nhập mã định danh riêng.');
                return;
            }

            // Gọi API để lấy USER_NAME từ USER_CODE
            const userResponse = await fetch(`https://artfinder-backend.vercel.app/admin/getUserName/${modifyContractData.userCode}`);
            if (!userResponse.ok) {
                const errorMessage = await userResponse.json();
                alert(errorMessage.message);
                return;
            }
            const userData = await userResponse.json();
            const userName = userData.userName;

            // Thêm trường UPDATED_AT với thời gian hiện tại
            const modifiedData = {
                ...modifyContractData,
                NGUOI_DIEU_CHINH: userName,
                UPDATED_AT: moment().format('YYYY-MM-DD HH:mm:ss')
            };

            // Tìm kiếm bản ghi trong channelInfo có trùng ID_KENH, SO_HOP_DONG, và SO_PHU_LUC
            const duplicateContract = channelInfo.find(contract =>
                contract.ID_KENH === modifyContractData.ID_KENH &&
                contract.SO_HOP_DONG === modifyContractData.SO_HOP_DONG &&
                contract.SO_PHU_LUC === modifyContractData.SO_PHU_LUC &&
                contract._id !== contractIdToModify // Loại bỏ bản ghi hiện tại đang được sửa đổi
            );

            if (duplicateContract) {
                // Nếu tìm thấy bản ghi trùng lặp, hiển thị thông báo và dừng lại
                alert('Không thể điều chỉnh vì trùng dữ liệu.');
                return;
            }

            // Nếu không có bản ghi trùng lặp, tiếp tục gửi yêu cầu PUT đến backend
            const response = await fetch(`https://artfinder-backend.vercel.app/admin/admin-edit-channelInfo/${contractIdToModify}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(modifiedData),
            });

            if (response.ok) {

                setChannelInfo(prevChannelInfo => {

                    const updatedChannelInfo = prevChannelInfo.map(contract => {
                        if (contract._id === contractIdToModify) {
                            return {
                                ...contract,
                                TINH_TRANG: modifyContractData.TINH_TRANG,
                                SO_HOP_DONG: modifyContractData.SO_HOP_DONG,
                                SO_PHU_LUC: modifyContractData.SO_PHU_LUC,
                                LINH_VUC: modifyContractData.LINH_VUC,
                                NGAY_KY: modifyContractData.NGAY_KY,
                                TEN_KENH: modifyContractData.TEN_KENH,
                                ID_KENH: modifyContractData.ID_KENH,
                                NGUOI_THUC_HIEN: modifyContractData.NGUOI_THUC_HIEN,
                                GHI_CHU: modifyContractData.GHI_CHU,
                            };
                        }
                        return contract;
                    });
                    return updatedChannelInfo;
                });

                // Hiển thị thông báo
                alert('Cập nhật thành công');
                setShowConfirmModify(false);
            } else {
                // Nếu có lỗi xảy ra, hiển thị thông báo lỗi
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error('Lỗi:', error);
            alert('Có lỗi xảy ra');
        }
        setContractIdToModify(null);
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            // Đọc dữ liệu và chuyển đổi ngày tháng
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                raw: false, // Chuyển đổi giá trị thành chuỗi, không giữ nguyên giá trị gốc
                dateNF: 'yyyy-mm-dd', // Định dạng ngày tháng mong muốn
            });

            setJsonData(jsonData);
            // console.log("TEST-DATA-JSON",jsonData )
        };

        reader.readAsArrayBuffer(file);
    };



    const importContracts = async () => {

        try {
            if (!jsonData) {
                alert("Vui lòng chọn file Excel.");
                return;
            }

            // Kiểm tra jsonData có dữ liệu hay không
            if (jsonData.length === 0) {
                alert("File Excel hiện đang không có dữ liệu.");
                return;
            }
            setLoading(true); // Đặt trạng thái loading thành true trước khi bắt đầu request

            const response = await fetch('https://artfinder-backend.vercel.app/admin/import-multiple-channelInfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ contracts: jsonData }),
            });

            const result = await response.json();

            if (response.ok) {
                alert('Thông tin đã được thêm mới thành công.');
                // Sau khi thêm thành công, bạn có thể thực hiện các thao tác khác ở đây

                // Đóng popup
                setAddNewContractPopup(false);
            } else {
                // Handle server error response
                const errorMessage = result.error || 'Something went wrong.';
                alert(errorMessage); // Show alert to user
                console.error('Server Error:', errorMessage);
            }

        } catch (error) {
            console.error('Error:', error);
            alert('Network error or server is down.'); // Show alert for network or server error
        }
        finally {
            setLoading(false); // Đặt trạng thái loading thành false sau khi request hoàn tất
        }
    };




    return (
        <div className="manage-contracts-container">
            <h2 className="manage-contracts-title">Danh Sách Đối Chiếu Youtube</h2>
            <button className="manage-contracts-add-contract-button" onClick={handleAddContractClick} >Thêm mới</button>

            {addNewContractPopup && (
                <div className="managecontracts-addnewcontract-modal">
                    <div className="managecontracts-addnewcontract-modal-content">
                        <h2 className='managecontracts-addnewcontract-modal-title'>Thêm mới</h2>

                        {/* Tab navigation */}
                        <div className="tabs">
                            <button className={`tab ${isIndividualTab ? 'active' : ''}`} onClick={() => setIsIndividualTab(true)}>
                                Nhập thông tin
                            </button>
                            <button className={`tab ${!isIndividualTab ? 'active' : ''}`} onClick={() => setIsIndividualTab(false)}>
                                File Excel
                            </button>
                        </div>

                        {/* Tab content */}
                        <div className="tab-content">
                            {isIndividualTab ? (
                                <div className="input-fields">
                                    <div className='input-fields-group'>
                                        <input
                                            type="text"
                                            name="tinh_trang"
                                            placeholder="Tình trạng"
                                            value={newContractData.tinh_trang}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="so_hop_dong"
                                            placeholder="Số hợp đồng"
                                            value={newContractData.so_hop_dong}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="so_pl"
                                            value={newContractData.so_pl}
                                            placeholder="Số phụ lục"
                                            onChange={handleInputChange}
                                            required
                                        />

                                        <select
                                            name="linh_vuc"
                                            value={newContractData.linh_vuc}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="" disabled hidden>Lĩnh vực</option>
                                            <option value="SCTT">SCTT</option>
                                            <option value="Biểu diễn">Biểu diễn</option>
                                            <option value="Biểu diễn">Website</option>

                                        </select>
                                        <input
                                            type="text"
                                            name="ngay_ky"
                                            value={newContractData.ngay_ky}
                                            placeholder="Ngày ký"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className='input-fields-group'>
                                        <input
                                            type="text"
                                            name="ten_kenh_Chuong_trinh"
                                            value={newContractData.ten_kenh_Chuong_trinh}
                                            placeholder="Tên Kênh Chương trình"
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            type="text"
                                            name="link_kenh"
                                            value={newContractData.link_kenh}
                                            placeholder="Link Kênh"
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="nguoi_thuc_hien"
                                            value={newContractData.nguoi_thuc_hien}
                                            placeholder="Người thực hiện"
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            type="text"
                                            name="ghi_chu"
                                            value={newContractData.ghi_chu}
                                            placeholder="Ghi chú"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="button-container">
                                        <button className='addnewcontract-modal-save-btn' onClick={handleAddChannelInfo}>Lưu</button>
                                        <button className='addnewcontract-modal-remove-btn' onClick={() => setAddNewContractPopup(false)}>Hủy</button>
                                    </div>
                                </div>
                            ) : (
                                <div className="upload-container">
                                    <input
                                        type="file"
                                        accept=".xlsx, .xls"
                                        onChange={handleFileUpload}
                                        id="file-upload"
                                        className='attachment-file'
                                    />

                                    <div className="button-container">
                                        <button className='addnewcontract-modal-save-btn' onClick={importContracts}>Lưu</button>
                                        <button className='addnewcontract-modal-remove-btn' onClick={() => setAddNewContractPopup(false)}>Hủy</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}





            {/* SEARCH FORM */}
            <form onSubmit={handleSearch} className="manage-contracts-search-form">
                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo số hợp đồng"
                        value={searchByContractNumber}
                        onChange={(e) => setSearchByContractNumber(e.target.value)}
                    />
                </div>

                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo tên Kênh"
                        value={searchByProgramName}
                        onChange={(e) => setSearchByProgramName(e.target.value)}
                    />
                </div>

                <div className="manage-contracts-search-input">
                    <input
                        type="text"
                        placeholder="Tìm kiếm theo tên ID Kênh"
                        value={searchByIdChannel}
                        onChange={(e) => setSearchByIdChannel(e.target.value)}
                    />
                </div>

                <button className="manage-contracts-search-button" type="submit">
                    Tìm kiếm
                </button>
            </form>






            {loading &&
                <div class="overlay">
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            }
            {/* ERROR INFO */}
            {error && <p className="error">{error}</p>}



            {/* CONTRACT INFO */}
            {channelInfo && (
                <div>
                    <p className='total-contract'>Kết quả tìm được: {channelInfo.length}</p>

                    <table className="manage-contracts-table">
                        <thead>
                            <tr>
                                <th className="column-stt">STT</th>
                                <th>Tình trạng</th>
                                <th>Số hợp đồng</th>
                                <th>Số phụ lục</th>
                                <th>Lĩnh vực</th>
                                <th className='column-date'>Ngày ký</th>
                                <th>Tên Kênh</th>
                                <th>ID Kênh</th>
                                <th>Người thực hiện</th>
                                <th>Ghi chú</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {channelInfo.map((contract, index) => (
                                <tr key={index}>
                                    <td>{index + 1}.</td>
                                    <td>{contract.TINH_TRANG}</td>
                                    <td>{contract.SO_HOP_DONG}</td>
                                    <td>{contract.SO_PHU_LUC}</td>
                                    <td>{contract.LINH_VUC}</td>

                                    <td>{formatDate(contract.NGAY_KY)}</td>
                                    <td>{contract.TEN_KENH}</td>
                                    <td>
                                        {contract.ID_KENH}
                                    </td>
                                    <td>{contract.NGUOI_THUC_HIEN}</td>
                                    <td>{contract.GHI_CHU}</td>
                                    <td className='btn-container'>
                                        <button className='btn-modify' onClick={() => promptModifyContract(contract._id)}>Điều chỉnh</button>
                                        <button className='btn-viewhistory' onClick={() => promptViewContractHistory(contract._id)}>Xem lịch sử</button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            )}

            {showHistoryModal && (
                <div className='checklist-viewhistory-modal'>
                    <div className='checklist-viewhistory-modal-content'>
                        <h2>Thông tin lịch sử</h2>
                        <button className='close-button' onClick={() => setShowHistoryModal(false)}>Đóng</button>
                        <div className='checklist-viewhistory-list'>
                            <table className='history-table'>
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Trạng thái</th>
                                        <th>Số hợp đồng</th>
                                        <th>Số phụ lục</th>
                                        <th>Lĩnh vực</th>
                                        <th>Ngày ký</th>
                                        <th>Tên kênh</th>
                                        <th>ID kênh</th>
                                        <th>Người thực hiện</th>
                                        <th>Ghi chú</th>
                                        <th>Người điều chỉnh</th>
                                        <th>Ngày thực hiện</th>
                                        <th>Ngày cập nhật</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historyData.map((history, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}.</td>
                                            <td>{history.TINH_TRANG}</td>
                                            <td>{history.SO_HOP_DONG}</td>
                                            <td>{history.SO_PHU_LUC}</td>
                                            <td>{history.LINH_VUC}</td>
                                            <td>{moment(history.NGAY_KY).format('YYYY-MM-DD')}</td>
                                            <td>{history.TEN_KENH}</td>
                                            <td>{history.ID_KENH}</td>
                                            <td>{history.NGUOI_THUC_HIEN}</td>
                                            <td>{history.GHI_CHU}</td>
                                            <td>{history.NGUOI_DIEU_CHINH}</td>
                                            <td>{history.CREATED_AT && moment(history.CREATED_AT).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>{history.UPDATED_AT && moment(history.UPDATED_AT).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}



            {showConfirmDelete && (
                <div className="confirm-delete-modal">
                    <div className="confirm-delete-modal-content">
                        <h2 className='confirm-delete-modal-title'>Bạn có chắc muốn xóa mục này?</h2>
                        <button onClick={confirmDeleteContract} className="confirm-delete-modal-confirm-btn">Xác nhận</button>
                        <button onClick={() => setShowConfirmDelete(false)} className="confirm-delete-modal-cancel-button">Hủy</button>
                    </div>
                </div>
            )}


            {showConfirmModify && (
                <div className="managecontracts-modifycontract-modal">
                    <div className="managecontracts-modifycontract-modal-content">
                        <h2 className='managecontracts-modifycontract-modal-title'>Chỉnh sửa thông tin</h2>
                        <div className="input-fields">
                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="TINH_TRANG"
                                    placeholder="Tình trạng"
                                    value={modifyContractData.TINH_TRANG}
                                    onChange={handleModifyInputChange}
                                />
                                <input
                                    type="text"
                                    name="SO_HOP_DONG"
                                    placeholder="Số hợp đồng"
                                    value={modifyContractData.SO_HOP_DONG}
                                    onChange={handleModifyInputChange}
                                    required
                                />

                                <input
                                    type="text"
                                    name="SO_PHU_LUC"
                                    value={modifyContractData.SO_PHU_LUC}
                                    placeholder="Số phụ lục"
                                    onChange={handleModifyInputChange}
                                    required
                                />

                                <input
                                    type="text"
                                    name="LINH_VUC"
                                    value={modifyContractData.LINH_VUC}
                                    onChange={handleModifyInputChange}
                                />

                                <input
                                    type="text"
                                    name="NGAY_KY"
                                    value={modifyContractData.NGAY_KY}
                                    placeholder="Ngày ký"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                            </div>


                            <div className='input-fields-group'>
                                <input
                                    type="text"
                                    name="TEN_KENH"
                                    value={modifyContractData.TEN_KENH}
                                    placeholder="Tên Kênh Chương trình"
                                    onChange={handleModifyInputChange}
                                />
                                <input
                                    type="text"
                                    name="ID_KENH"
                                    value={modifyContractData.ID_KENH}
                                    placeholder="Link Kênh"
                                    onChange={handleModifyInputChange}
                                    required
                                />

                                <input
                                    type="text"
                                    name="NGUOI_THUC_HIEN"
                                    value={modifyContractData.NGUOI_THUC_HIEN}
                                    placeholder="ID Link"
                                    onChange={handleModifyInputChange}

                                />
                                <input
                                    type="text"
                                    name="GHI_CHU"
                                    value={modifyContractData.GHI_CHU}
                                    placeholder="Ghi chú"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="userCode"
                                    value={modifyContractData.userCode}
                                    placeholder="Mã định danh riêng"
                                    onChange={handleModifyInputChange}
                                    required
                                />
                            </div>


                            <div className="button-container">
                                <button className='modifycontract-modal-save-btn' onClick={confirmModifyContract}>Lưu</button>
                                <button className='modifycontract-modal-cancel-btn' onClick={() => setShowConfirmModify(false)}>Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}






        </div>
    )
}

export default ManagYoutubeCheckList;
